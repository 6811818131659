import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import $ from "jquery"
import Metatags from "../../components/metatags"
import Navbar from "../../components/navbar"
import Gallery from "../../components/projects-gallery"
import MobGallery from "../../components/mob-gallery-comp"
import Footer from "../../components/footer"

const Hotel5Estrellas = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const setVideoWindow = () => {
    setWindowWidth($(window).width())
  }

  useEffect(() => {
    setWindowWidth($(window).width())
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setVideoWindow)
    }
  }, [])

  return (
    <>
      <Metatags
        title="daucodesigns | proyecto - hotel 5 estrellas"
        description="detrás de cada producto, hay una historia"
        url="https://daucodesigns.com"
        image="https://daucodesigns.com/logo-daucodesigns.png"
        keywords="muebles, design"
      />
      <Navbar />
      <div className="height40" />
      <div className="height20" />
      <div id="project-header">
        <Container style={{ maxWidth: "1300px" }}>
          <Row>
            <Col>
              <div className="text-center projects">
                <h4 className="marcas-title nuestras-marcas-title">
                  hotel boutique
                </h4>
                <h5>sitges</h5>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="height40" />
      </div>
      {windowWidth >= 768 ? (
        <Gallery
          imagesArray={data.projectImages.nodes}
          company="hotel boutique"
        />
      ) : (
        <MobGallery
          imagesArray={data.projectImages.nodes}
          ratio={765 / 1460}
          company="hotel boutique"
          windowWidth={windowWidth}
          leftArrow={data.leftArrow}
          rightArrow={data.rightArrow}
        />
      )}
      <div className="height40" />
      <Container>
        <Row>
          <Col>
            <div className="text-center projects">
              <h5>Estilo a pie de mar</h5>
            </div>
          </Col>
        </Row>
        <div className="height20" />
        <Row>
          <Col>
            <p>
              Ubicado en primera linea del paseo de Sitges, el Hotel boutique
              Casa Vilella es un edificio del estilo indiano muy peculiar de
              Sitges. Alberga 16 habitaciones creadas con un especial cuidado al
              detalle de cada elemento. Desde el suelo, o los muebles hasta las
              cornisas, todo respira el cariño de las cosas bien hechas.
            </p>
            <p>
              Los muebles de la terraza, el restaurante y las habitaciones son
              tambien parte de este reflejo.
            </p>
            <p>
              Se utilizaron mesas de Andreu World, sofás cama de Milano Bedding
              y sillas de restaurante customizadas.
            </p>
          </Col>
        </Row>
      </Container>
      <div className="height40" />
      <div className="height40" />
      <Footer />
    </>
  )
}

export default Hotel5Estrellas

export const query = graphql`
  {
    leftArrow: file(relativePath: { eq: "left.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    rightArrow: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projectImages: allFile(
      filter: { relativePath: { regex: "/projects/hotel5/" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childrenImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  }
`
